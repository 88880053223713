import { createApp } from 'vue';
import './main.css';
import { initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

// import ElementPlus from 'element-plus';
// import 'element-plus/dist/index.css';
import App from './App.vue';
import router from './router';

import './assets/main.css';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_apiKey,
  authDomain: import.meta.env.VITE_authDomain,
  projectId: import.meta.env.VITE_projectId,
  storageBucket: import.meta.env.VITE_storageBucket,
  messagingSenderId: import.meta.env.VITE_messagingSenderId,
  appId: import.meta.env.VITE_appId,
  measurementId: import.meta.env.VITE_measurementId,
};
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
if (import.meta.env.DEV) {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(getFunctions(app), 'localhost', 5001);
}

const vueApp = createApp(App);
vueApp.use(router);
// vueApp.use(ElementPlus);
vueApp.mount('#app');
