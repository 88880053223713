import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import SummaryView from '@/views/SummaryView.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/summary/:uid/:date/:month/:year',
      name: 'summary',
      component: SummaryView,
    },
    {
      path: '/summary/:uid',
      redirect: (to) => {
        let today = new Date();

        return `/summary/${to.params.uid}/${today.getDate()}/${
          today.getMonth() + 1
        }/${today.getFullYear()}`;
      },
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AboutView.vue'),
    },
  ],
});

export default router;
